import React from 'react'
import { MdArrowForward } from 'react-icons/md'

import Layout from 'components/layout'
import Button from 'components/button'

const IndexPage = ({ location }) => (
  <Layout location={location} showSidebar={false}>
    <p>
      ClearView Flex enables key creatives and production executives to view a
      live, encrypted video stream in real-time, on your mobile device or PC,
      from anywhere – without getting on a plane. (Peanuts not included.)
    </p>
    <Button to="/docs/introduction/">
      View the docs <MdArrowForward />
    </Button>
  </Layout>
)

export default IndexPage
