import React from 'react'
import { Link } from 'gatsby'

import { css } from 'emotion'

import { rhythm } from 'css/typography'
import { baseSpacing, colors, radius } from 'css/constants'
import hexToRgba from 'utils/hexToRgba'

const button = css({
  display: 'inline-flex',
  alignItems: 'center',
  padding: `${rhythm(2 / 5)} ${rhythm(1 / 2)}`,
  color: '#FFFFFF',
  background: colors.blue,
  boxShadow: `0 2px ${radius} ${hexToRgba(colors.darkerGrey, 0.3)}`,
  borderRadius: radius,
  '& svg': {
    marginLeft: baseSpacing / 4,
  },
  '&:hover, &:focus': {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
})

const Button = ({ children, to }) => (
  <Link className={button} to={to}>
    {children}
  </Link>
)

export default Button
