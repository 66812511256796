/**
 * hexToRgba
 *
 * It h a hex color into RGBA and allows to specify opacity
 * @param  {string} hex       The hex color to be converted
 * @param  {Number} alpha     The optional opacity to apply to the color
 * @return {string}           The resulting RGBA color
 */
const hexToRgba = (hex, alpha = 1) => {
  let hexString = hex

  if (hexString.startsWith('#')) {
    hexString = hex.substr(1)
  }
  if (hexString.length === 3) {
    hexString = hexString
      .split('')
      .map(char => `${char}${char}`)
      .join('')
  }

  const r = parseInt(hexString.slice(0, 2), 16)
  const g = parseInt(hexString.slice(2, 4), 16)
  const b = parseInt(hexString.slice(4, 6), 16)

  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return null
  }

  return `rgba(${r}, ${g}, ${b}, ${isNaN(alpha) ? 1 : alpha})`
}

export default hexToRgba
